body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.table-camera-list {
  min-width: 1230px;
}

.table-camera-list>:not(caption)>*>* {
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.list-setting {
  max-width: 750px;
}

.btn-outline-primary-cus {
  color: #ffffff;
  border-color: #0d6efd;
  background-color: #0d6efd;
}

.btn-outline-primary-cus:hover,
.btn-outline-primary-cus:disabled {
  color: #ffffff;
  border-color: #0b5ed7;
  background-color: #0b5ed7;
}

.btn-outline-warning-cus {
  color: #ffffff;
  border-color: #ffc35f;
  background-color: #ffc35f;
}

.btn-outline-warning-cus:hover,
.btn-outline-warning-cus:disabled {
  color: #ffffff;
  border-color: #ffc107;
  background-color: #ffc107;
}

#tooltip-password, #tooltip-password .tooltip-inner, #tooltip-password ul {
  text-align: left;
  max-width: 450px;
}
#tooltip-password ul {
  padding: 0 0 0 12px;
  margin: 0;
}
#tooltip-password li {
  padding: 0;
  margin: 0;
}

#button-tooltip, #button-tooltip .tooltip-inner,
#button-tooltip-interval, #button-tooltip-interval .tooltip-inner {
  text-align: left;
  max-width: 350px;
}

@media screen and (max-width: 1520px) {
  span.sp-text {
    display: block;
  }
}
span.update-by {
  display: block;
  max-width: 250px;
  overflow-wrap: break-word;
  word-break: break-word;
}