.DeviceSelectHelpText {
    position: relative
}

.DeviceSelectHelpText::before {
    content: "デバイス・カメラの絞り込み機能を使用する場合、グラフが表示されるまでに時間がかかる場合があります。";
    font-size: 14px;
    color: #000000;
    position: absolute;
    top: -20px;
    left: 15px;
}